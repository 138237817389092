<template>
  <Layout>
    <div class="loader-wrap text-center h-100vh position-relative" v-if="loader">
      <b-spinner class="align-middle" variant="primary"></b-spinner>
    </div>
    <PageHeader v-if="!loader" :title="'DETAILS OF USER: ' + fullName" />
     <b-card v-if="!loader">
      <b-row class="pt-2 pb-2">
        <b-col>
          <h5 class="mb-0" v-if="this.$route.params.id">User ID : {{ this.$route.params.id }}</h5>
        </b-col>
      </b-row>

      <b-row class="align-items-center">
        <b-col cols="12" md="8">
          <h5 class="mb-0" v-if="products.total_orders">Total number of Orders: {{ products.total_orders }}</h5>
        </b-col>
        <b-col cols="12" md="4" class="mb-3 d-flex align-items-center">
          <date-picker v-model="time1" range @confirm="handleDates($event)" @clear="resetDatesFilter" :confirm="true"
            confirm-text="Filter" :show-second="false" placeholder="Filter products from specific dates"></date-picker>
        </b-col>
      </b-row>
      
      <b-table v-if="products.data" striped :busy="loader" :items="products.data" :fields="fields">
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Loading...</strong>
          </div>
        </template>

        <template v-slot:cell(post_title)="row">
          #{{ row.value }}
        </template>

        <template v-slot:cell(order_total)="row">
          £ {{ row.value }}
        </template>

        <template v-slot:cell(variations)="row">
          <template v-if="row.item.variations">           
          <span v-for="(variation, index) in row.item.variations" :key="index">
          {{ variation.variation_name +': '+ variation.name}}
          <span v-if="(variation.variation_name != 'Sharps Bin') && (variation.variation_name != 'Needles')">, Quantity: {{ variation.qty ? variation.qty : 1 }}</span>
          </span>
          </template>
        </template>

        <template v-slot:cell(action)="row">
          <router-link :to="'/order/' + row.item.ID" class="mr-3 text-success" v-b-tooltip.hover title="View">
            <i class="mdi mdi-eye font-size-18"></i>
          </router-link>
        </template>
        <template v-slot:cell(payment_status)="row">
          <div class="badge font-size-12" :class="{
            'badge-soft-danger': `${row.value}` === 'Chargeback',
            'badge-soft-success': `${row.value}` === 'successful',
            'badge-soft-warning': `${row.value}` === 'Unpaid'
          }">{{ row.value }}</div>
        </template>
        <template v-slot:cell(age_checked)="row">
          <div class="badge font-size-12" :class="{
            'badge-soft-danger': `${row.value}` === 'unverified',
            'badge-soft-success': `${row.value}` === 'verified',
            'badge-soft-warning': `${row.value}` === 'not_attempt'
          }">{{ row.value }}</div>
        </template>
      </b-table>
      <b-table v-else :items="noData" :fields="fields"> 
        <template v-slot:cell(age_checked)="row">
          <h5 class="mt-4 mb-0">{{ row.value }}</h5>
        </template>
      </b-table>
    </b-card>
  </Layout>
</template>

<script>
import {
  userOrdersdMethods,
  userOrderGetters,
  globalGetters,
} from "@/state/helpers";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
// import axios from 'axios';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { format } from 'date-fns'
export default {
  components: {
    Layout,
    PageHeader,
    DatePicker
  },
  data() {
    return {
      filterFrom: '',
      filterTo: '',
      fields: [
        { key: "post_title", label: "Order ID" },
        { key: "date" },
        { key: "first_name", label: "First Name" },
        { key: "last_name", label: "Last Name" },
        { key: "product", label: "Product" },
        { key: "variations", label: "Variations" },
        { key: "age_checked", label: "Age Checked" },
        { key: "bmi", label: "BMI" },
        { key: "status", label: "Status" },
        { key: "payment_status", label: "Payment Status" },
        { key: "order_total", label: "Total" },
        { key: "action" }
      ],
      noData:[{
        age_checked : 'No Orders Data Found'
      }],
      time1: null,
    }
  },
  created() {
    let options = { 
      userId:this.$route.params.id,
      filterFrom : this.filterFrom,
      filterTo : this.filterTo
    }
    this.getUserOrders(options);
  },
  computed: {
    ...userOrderGetters,
    ...globalGetters,
    fullName() {
      if(this.userData.first_name && this.userData.last_name){
         return this.userData.first_name + ' ' + this.userData.last_name;
      }
      return this.userData.display_name;
    }
  },
  methods: {
    ...userOrdersdMethods,
    handleDates(event) {
      this.filterFrom = format(new Date(event[0]), 'yyyy-MM-dd');
      this.filterTo = format(new Date(event[1]), 'yyyy-MM-dd');
      let options = { 
        userId:this.$route.params.id,
        filterFrom : this.filterFrom,
        filterTo : this.filterTo
      }
      this.getUserOrders(options);
    },
    resetDatesFilter(){
      this.filterFrom = '';
      this.filterTo = '';
      let options = { 
        userId:this.$route.params.id,
        filterFrom : this.filterFrom,
        filterTo : this.filterTo
      }
      this.getUserOrders(options);
    }
  }
}
</script>